<template>
  <div class="input-group mb-3" >
    <input :value="value" @blur="$emit('passwordChanged', $event.target.value)" :type="passwordFieldType" 
      class="form-control" placeholder="Wachtwoord" aria-label="Wachtwoord">
    <button class="btn btn-outline-secondary" type="button" @click="togglePasswordVisibility">
      <i :class="{'bi-eye': passwordFieldType === 'password', 'bi-eye-slash': passwordFieldType !== 'password'}"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'PasswordInput',
  props: ['value'],
  emits: ['passwordChanged'],
  data() {
    return {
      passwordFieldType: 'password',
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
  },
};
</script>