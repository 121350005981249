<template>
  <div class="container mt-4">
    <h1>Log Viewer</h1>
    <div class="row mb-3 m-0 p-0">
      <div class="col-md-8 m-0 p-0">
        <div class="row m-0 p-0">
          <div class="col-md-12 p-md-0"> 
            <div class="mb-3 px-md-1 "> 
              <label for="logFile" class="form-label">Select Log File</label>
              <select id="logFile" class="form-select" v-model="selectedLogFile" @change="fetchLogs">
                <option v-for="file in logFiles" :key="file" :value="file">{{ file }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-md-6 px-md-1 ">
            <label for="startTime" class="form-label">Start Time</label>
            <input type="time" id="startTime" class="form-control" v-model="startTime" @change="filterLogs">
          </div>
          <div class="col-md-6 px-md-1">
            <label for="endTime" class="form-label">End Time</label>
            <input type="time" id="endTime" class="form-control" v-model="endTime" @change="filterLogs">
          </div>
        </div>
      </div>
      <div class="col-md-4 ">
        <label for="logLevel" class="form-label">Select Log Level(s)</label>
        <select id="logLevel" class="form-select" multiple v-model="selectedLogLevels" @change="filterLogs">
          <option value="info">Info</option>
          <option value="warn">Warning</option>
          <option value="error">Error</option>
        </select>
      </div>
    </div>
    <div v-if="filteredLogs.length > 0">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" @click="changePage(1)" href="#">First</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" @click="changePage(currentPage - 1)" href="#">Previous</a>
          </li>
          <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" @click="changePage(page)" href="#">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" @click="changePage(currentPage + 1)" href="#">Next</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" @click="changePage(totalPages)" href="#">Last</a>
          </li>
        </ul>
      </nav>
      <div class="accordion mb-3" id="logAccordion">
        <div v-for="(log, index) in paginatedLogs" :key="index" class="accordion-item">
          <h2 class="accordion-header" :id="'heading' + index" ref="accordionHeaders">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
              <div style="display: flex; width: 100%; overflow: hidden;">
                <div style="margin-right: 5px;">
                  <span :class="logClass(log.level)">{{ formatDate(log.timestamp) }} - {{ log.level.toUpperCase() }}</span>
                </div>
                <div style="max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" 
                     data-bs-toggle="tooltip" 
                     :title="log.message">
                  {{ getEndpoint(log.message) }}
                </div>
              </div>
            </button>
          </h2>
          <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#logAccordion" ref="accordionBodies">
            <div class="accordion-body">
              <pre v-if="log.message">{{ log.message }}</pre>
              <pre v-if="log.stack">{{ log.stack }}</pre>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" @click="changePage(1)" href="#">First</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" @click="changePage(currentPage - 1)" href="#">Previous</a>
          </li>
          <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" @click="changePage(page)" href="#">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" @click="changePage(currentPage + 1)" href="#">Next</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" @click="changePage(totalPages)" href="#">Last</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>


<script>
import moment from 'moment';
import ServiceFactory from '@/services/ServiceFactory';

export default {
  name: 'LogViewerPage',
  data() {
    return {
      logFiles: [],
      selectedLogFile: '',
      logs: [],
      filteredLogs: [],
      selectedLogLevels: [],
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      currentPage: 1,
      logsPerPage: 15,
      maxPagesToShow: 5,
      logService: null,
      webSocketService: null
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredLogs.length / this.logsPerPage);
    },
    paginatedLogs() {
      const start = (this.currentPage - 1) * this.logsPerPage;
      const end = start + this.logsPerPage;
      return this.filteredLogs.slice(start, end);
    },
    visiblePages() {
      const pages = [];
      const half = Math.floor(this.maxPagesToShow / 2);
      let start = Math.max(1, this.currentPage - half);
      let end = Math.min(this.totalPages, start + this.maxPagesToShow - 1);

      if (end - start + 1 < this.maxPagesToShow) {
        start = Math.max(1, end - this.maxPagesToShow + 1);
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  watch: {
    logFiles: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.selectedLogFile = newVal[0];
          this.fetchLogs();
        }
      }
    }
  },
  methods: {
    async fetchLogFiles() {
      try {
        const response = await this.logService.getLogFiles();
        console.log('LogViewerPage.vue: fetchLogFiles: response: ', response);
        this.logFiles = response.sort((a, b) => b.localeCompare(a));
      } catch (error) {
        console.error('Error fetching log files:', error);
      }
    },
    async fetchLogs() {
      if (!this.selectedLogFile) return;
      try {
        const response = await this.logService.getLogs(this.selectedLogFile);
        this.logs = response.sort((a, b) => b.timestamp > a.timestamp ? 1 : -1);
        const firstLogDate = moment(this.logs[0].timestamp).format('YYYY-MM-DD');
        this.startDate = firstLogDate;
        this.endDate = firstLogDate;
        this.startTime = '00:00';
        this.endTime = '23:59';
        this.filterLogs();
        this.currentPage = 1;
      } catch (error) {
        console.error('Error fetching logs:', error); 
      }
    },
    getEndpoint(message) {
      const match = message.match(/(GET|POST|PUT|DELETE|OPTIONS|HEAD|PATCH) (\S+)/);
      return match ? match[0] : message;
    },
    formatDate(timestamp) {
      return moment(timestamp).format('HH:mm:ss');
    },
    logClass(level) {
      switch (level) {
        case 'info':
          return 'badge bg-success text-white';
        case 'warn':
          return 'badge bg-warning text-dark';
        case 'error':
          return 'badge bg-danger text-white';
        default:
          return '';
      }
    },
    closeAllAccordions() {
      this.$refs.accordionBodies.forEach(accordion => {
        accordion.classList.remove('show');
      });
      this.$refs.accordionHeaders.forEach(header => {
        const button = header.querySelector('.accordion-button');
        if (button) {
          button.classList.add('collapsed');
          button.setAttribute('aria-expanded', 'false');
        }
      });
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.closeAllAccordions();
        this.currentPage = page;
      }
    },
    filterLogs() {
      this.filteredLogs = this.logs.filter(log => {
        const logTime = moment(log.timestamp).format('HH:mm');
        const withinTimeRange = (!this.startTime || logTime >= this.startTime) &&
                                (!this.endTime || logTime <= this.endTime);
        const matchesLogLevel = this.selectedLogLevels.length === 0 || 
                                this.selectedLogLevels.includes(log.level);
        return withinTimeRange && matchesLogLevel;
      });
      this.currentPage = 1;
    }
  },
  mounted() {
    this.logService = ServiceFactory.getService('LogService')
    this.fetchLogFiles();


    this.webSocketService = ServiceFactory.getService('WebSocketService')

    this.webSocketService.addEventListener('logsUpdated', (event) => {
      //prevent infinite loop
      console.log('LogViewerPage.vue: webSocketService: logsUpdated: event: ', event.detail?.message);
      if(event.detail?.message && !event.detail.message.includes('GET /api/logs')) { 
        this.fetchLogs();
      }
    });
    this.webSocketService.connect()
  }
};
</script>



