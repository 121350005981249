import axios from 'axios';
import localDB from '../utils/localDB';

const rootUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.VUE_APP_API_URL || '';
  }
  return ''; // Gebruik een lege string voor productie/acceptatie
}

const api = axios.create({
  baseURL: rootUrl(),
});

// Request interceptor
api.interceptors.request.use(async (config) => {
  const token = await localDB.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;
