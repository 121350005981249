import api from './axiosConfig';
import CustomEventDispatcher from './CustomEventDispatcher';

class InvoiceService extends CustomEventDispatcher {

  constructor() {
    super()
    this.response = {};
  }

  async getInvoices(page = 1, pageSize = 50) {
    const url = `/api/invoices?page=${page}&pageSize=${pageSize}`;
    try {
      const response = await api.get(url, {
        credentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const event = new CustomEvent('invoicesLoaded', { detail: response.data });
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        console.error('Er is een fout opgetreden in getInvoices():', error);
        this.dispatchCustomEvent('getInvoicesFailed', error.response ? error.response.data : error);
      }
    }
  }

  async getInvoice(invoiceId) {
    const url = `/api/invoice/${invoiceId}`;
    try {
      const response = await api.get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const invoice = response.data;
      const event = new CustomEvent('invoiceLoaded', { detail: invoice });
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);
      return invoice;
    } catch (error) {
      console.error('Er is een fout opgetreden in getInvoice():', error);
      if (error.response && error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        this.dispatchCustomEvent('getInvoiceFailed', error.response ? error.response.data : error);
      }
    }
  }

  async retryInvoice(invoice) {
    const url = `/api/invoice/retry/${invoice.id}`;
    try {
      console.log('invoice:', invoice.xml_object);
      const response = await api.put(url, invoice.xml_object, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const updatedInvoice = response.data;
      const event = new CustomEvent('invoiceUpdated', { detail: updatedInvoice });
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);
      console.log('updatedInvoice:', updatedInvoice);
      return updatedInvoice;
    } catch (error) {
      console.error('Er is een fout opgetreden in updateInvoice():', error);
      if (error.response && error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        this.dispatchCustomEvent('updateInvoiceFailed', error.response ? error.response.data : error);
      }
    }
  }

  async getInvoiceStatussen() {
    const url = `/api/invoice/statussen`;
    try {
      const response = await api.get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const invoicestatussen = response.data;
      const event = new CustomEvent('invoicestatussenLoaded', { detail: invoicestatussen });
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);

      return invoicestatussen;
    } catch (error) {
      console.error('Er is een fout opgetreden in getInvoicestatussen():', error);
      if (error.response && error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        this.dispatchCustomEvent('getInvoiceStatussenFailed', error.response ? error.response.data : error);
      }
    }
  }

  async updateInvoiceStatus(invoiceId, statusId) {
    const url = `/api/invoice/${invoiceId}/status`;
    try {
      const response = await api.put(url, { statusId }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const updatedInvoice = response.data;
      const event = new CustomEvent('invoiceStatusUpdated', { detail: updatedInvoice });
      setTimeout(() => {
        this.dispatchEvent(event);
      }, 100);
      return { success: true, data: updatedInvoice };
    } catch (error) {
      console.error('Er is een fout opgetreden in updateInvoiceStatus():', error);
      if (error.response && error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        this.dispatchCustomEvent('updateInvoiceStatusFailed', error.response ? error.response.data : error);
      }
      return { success: false, message: error.response ? error.response.data : error.message };
    }
  }

  async tryXmlChanges(invoiceId, xmlContent) {
    try {
      const url = `/api/invoice/xml/try`;
      const response = await api.post(url, { id: invoiceId, xml: xmlContent }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Fout bij het proberen van XML-wijzigingen:', error);
      throw error;
    }
  }
}

export default InvoiceService;

