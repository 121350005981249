<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Orderpagina</h4>
      <div class="row p-0 m-0">
        <div class="col-lg-3 col-md-4 mt-2 ">
          <FilterOrder @filterStatusChanged="filterStatusChanged" :orderstatussen="orderstatussen" />
        </div>
        <div class="col-lg-9 col-md-8 ">
          <div class="table-responsive">
            <table class="table caption-top table-striped border table-hover">
              <caption>Lijst met orders</caption>
              <thead>
                <tr>
                  <th scope="col" @click="sort('status')" :class="sortClass('status')">&nbsp;</th>
                  <th scope="col" @click="sort('updated_at')" :class="sortClass('updated_at')" class="text-center">Verwerkt</th>
                  <th scope="col" @click="sort('customer')" :class="sortClass('customer')">Klant</th>
                  <th scope="col" @click="sort('reference')" :class="sortClass('reference')">Reference</th>
                  <th scope="col" @click="sort('vo_nummer')" :class="sortClass('vo_nummer')">Verkoopord.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in filteredOrders" :key="index" class="clickable-row"
                  @click="goToOrderDetailPage(index)">
                  <td><i class="bi bi-circle-fill close" :class="getColor(order.status)"></i></td>
                  <td class="text-center">{{ getDateTime(order.updated_at) }}</td>
                  <td>{{ order.customer?.name }}</td>
                  <td>{{ order.reference }}</td>
                  <td>{{ order.vo_nummer }}</td>
                </tr>
              </tbody>
            </table>
            <div ref="sentinel" ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ServiceFactory from "../services/ServiceFactory"
import FilterOrder from "../components/FilterOrder.vue"
import Utils from "../utils/Utils"
export default {
  name: 'OrdersPage',
  props: ['user'],
  components: { FilterOrder },
  data() {
    return {
      orderService: null,
      orders: [],
      orderstatussen: [],
      filteredOrders: [],
      currentSort: 'updated_at',
      currentSortDir: 'desc',
      webSocketService: null,
      page: 1,
      prevPage: 0,
      pageSize: 25,
      observer: null,
      currentFilter: null
    }
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.initializeOrdersAndWebSocket()
      }
    }
  },
  methods: {
    getColor(status) {
      return Utils.getColorForOrderStatus(status)
    },
    sort(s) {
      // als s al de huidige sorteerstatus is, keer dan de richting om
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSort = s;
      }

      this.filteredOrders = this.filteredOrders.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        let aValue = a[this.currentSort];
        let bValue = b[this.currentSort];

        // Als de waarde een object is, gebruik dan de label eigenschap
        if (typeof aValue === 'object' && aValue !== null && 'label' in aValue) {
          aValue = aValue.label;
        }
        if (typeof bValue === 'object' && bValue !== null && 'label' in bValue) {
          bValue = bValue.label;
        }

        // Behandel null waarden alsof ze altijd aan het einde moeten komen
        if (aValue === null) return 1;
        if (bValue === null) return -1;

        if (aValue < bValue) return -1 * modifier;
        if (aValue > bValue) return 1 * modifier;
        return 0;
      });
    },
    sortClass(column) {
      return {
        'sort-asc': this.currentSort === column && this.currentSortDir === 'asc',
        'sort-desc': this.currentSort === column && this.currentSortDir === 'desc'
      };
    },
    filterStatusChanged(filterObj) {
      this.currentFilter = filterObj;
      if(!this.orders) return [];
      this.filteredOrders = this.orders.filter((order) => {
        if (!order.status || !order.customer) {
          return true;
        }
        const statusMatch = filterObj.status[`status_${order.status.id}`] || this.allFiltersUnchecked(filterObj.status);
        if(order.customer?.username == 'frieslandcampina') {
          console.log('order.customer?.username: ', order.customer?.username)
          console.log('filterObj.customer: ', filterObj.customer)
        }
        const customerMatch = filterObj.customer[order.customer?.username?.toLowerCase()] || this.allFiltersUnchecked(filterObj.customer);
        const userAgentMatch = !filterObj.userAgentFilter || (order.user_agent && order.user_agent.toLowerCase().includes(filterObj.userAgentFilter.toLowerCase()));
        return statusMatch && customerMatch && userAgentMatch;
      });
    },
    allFiltersUnchecked(type) {
      for (let i in type) {
        if (type[i]) {
          return false;
        }
      }
      return true;
    },
    getDateTime(isoString) {
      return Utils.formatDateTimeString(isoString)
    },
    goToOrderDetailPage(index) {
      this.$router.push({ name: 'OrderDetail', params: { id: this.filteredOrders[index].id } });
    },
    async loadOrderstatussen() {
      this.orderstatussen = await this.orderService.getOrderstatussen();
      console.log('orderstatussen', this.orderstatussen)
    },
    async loadOrders() {
      //prevent double loading of the same page
      if(this.prevPage === this.page) return;
      this.prevPage = this.page;
      const newOrders = await this.orderService.getOrders(this.page, this.pageSize);
      if(!newOrders) return;
      newOrders.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      this.orders = [...this.orders, ...newOrders];
      this.filteredOrders = this.orders;
      this.page += 1;
      if (this.currentFilter) {
        this.filterStatusChanged(this.currentFilter);
        this.sort(this.currentSort); 
      }
    },
    observeSentinel() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadOrders(); 
          }
        });
      }, options);

      const sentinelElement = this.$refs.sentinel;

      if (sentinelElement instanceof Element) {
        this.observer.observe(sentinelElement);
      } else {
        console.error('Sentinel is not of type Element:', sentinelElement);
      }
    },
    async initializeOrdersAndWebSocket() {
      console.log('initializing orders and WebSocket: ', this.user)
      await this.loadOrders()
      await this.loadOrderstatussen()
      this.webSocketService = ServiceFactory.getService('WebSocketService')

      this.webSocketService.addEventListener('ordersUpdated', (event) => {
        this.orders = event.detail;
        this.orders.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        this.filteredOrders = this.orders
        this.$nextTick(() => this.filterStatusChanged(this.currentFilter));
      });
      this.webSocketService.connect()
    },
  },
  async mounted() {
    this.orderService = ServiceFactory.getService('OrderService')
    this.orderService.addEventListener('getOrdersFailed', () => {
      this.$emit('showToast', ['Fout bij ophalen orders', 'bg-danger']) 
    })
    console.log('mounted: ', this.user)
    if(this.user) {
      await this.initializeOrdersAndWebSocket()
    }
    this.observeSentinel(); 
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
</script>

<style scoped>
th {
  cursor: pointer;
}

.sort-asc::after {
  content: ' ▲';
}

.sort-desc::after {
  content: ' ▼';
}
</style>