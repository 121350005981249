<template>
  <div class="container">
    <ul class="nav nav-underline"> 
      <li class="nav-item">
        <a class="nav-link link-secondary" :class="{active: (page == 'orders' || page == 'OrderDetail')}" aria-current="page"
          @click="switchPage('orders')" href="#">Orders</a>
      </li>
      <li class="nav-item">
        <a class="nav-link link-secondary" :class="{active: page == 'orderbevestigingen'}" 
          @click="switchPage('orderbevestigingen')" href="#">Orderbevestigingen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link link-secondary" :class="{active: (page == 'invoices' || page == 'InvoiceDetail')}"  
          @click="switchPage('invoices')" href="#">Facturen</a>
      </li>
      <li class="nav-item" v-if="isAdmin">
        <a class="nav-link link-secondary" :class="{active: page == 'customers'}"  
          @click="switchPage('customers')" href="#">Klanten</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { getItem } from '@/utils/localDB';
import OAuth2AzureService from '@/services/OAuth2AzureService';

export default {
  name: "PageTabs",
  props: ['currentPage'],
  emits: ['switchPage'],
  data() {
    return {
      page: '',
      isAdmin: false,
      service: null,
    } 
  },
  async created() {
    this.service = new OAuth2AzureService();
    await this.checkAdminStatus();
  },
  watch: {
    currentPage(newValue) {
      if(!newValue) return;
      console.log('currentPage changed to: ', newValue)
      this.page = newValue
    }
  },
  methods: {
    switchPage(pageName) {
      this.$emit('switchPage', pageName)
      setTimeout(() => {
        this.page = pageName
      }, 200)
    },
    async checkAdminStatus() {
      const token = getItem('token');
      if (token) {
        try {
          const isAdmin = await this.service.checkAdminStatus(token);
          this.isAdmin = isAdmin;
        } catch (error) {
          console.error('Error checking admin status:', error);
          this.isAdmin = false;
        }
      }
    }
  }
}
</script>
