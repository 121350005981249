<template>
  <nav class="navbar navbar-expand-lg navbar-white bg-white text-primary fixed-top shadow">
    <div class="container main d-flex justify-content-between">
      <router-link class="nav-link" to="/orders"><img src="/../img/klinger_logo.svg" class="img-fluid" style="height:3rem;" /></router-link>
      <button class="navbar-toggler" type="button" 
              @click="toggleNavbar"
              aria-controls="navbarSupportedContent" 
              aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item"><a class="nav-link" href="https://www.klinger.nl/" target="blank">Klinger.nl</a></li>
              <li class="nav-item"><a class="nav-link" href="https://openai-backend-8nss.onrender.com/" target="blank">Chat</a></li>
              <li class="nav-item"><a class="nav-link" @click="goToPage('/logViewer')">Logs</a></li>
              <li class="nav-item"><a class="nav-link" @click="logoff">Uitloggen</a></li>
          </ul> 
      </div> 
    </div>
    <span class="ms-2 mt-5 me-2 version-number">v0.1.91</span>
  </nav>
</template>

<script>

export default {
  name: 'NavBar',
  props: ['formId'],
  methods: {
    logoff() {
      this.$emit('logoff')
    },
    goToPage(page) {

      this.$router.push(page)
    },
    toggleNavbar() {
      const navbarCollapse = document.getElementById('navbarSupportedContent');
      const collapse = new window.bootstrap.Collapse(navbarCollapse);
      collapse.toggle();
    }
  }
}
</script>

<style scoped>
.version-number {
  font-size: 10px;   
  color: rgba(0, 0, 0, 0.3); 
}
.nav-item .nav-link {
  cursor: pointer;
}
</style>
