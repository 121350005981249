import api from './axiosConfig';
import CustomEventDispatcher from './CustomEventDispatcher';

class CustomerService extends CustomEventDispatcher {

  async getCustomers() {
    try {
      const response = await api.get('/api/customers');
      const event = new CustomEvent('customersLoaded', { detail: response.data });
      this.dispatchEvent(event);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.dispatchUnauthorized();
      } else {
        console.error('Fout bij het ophalen van klanten:', error);
        this.dispatchCustomEvent('getCustomersFailed', error.response ? error.response.data : error);
      }
      throw error;
    }
  }

  async getCustomer(id) {
    try {
      const response = await api.get(`/api/customers/${id}`);
      const event = new CustomEvent('customerLoaded', { detail: response.data });
      this.dispatchEvent(event);
      return response.data;
    } catch (error) {
      console.error('Fout bij het ophalen van klant:', error);
      this.dispatchCustomEvent('getCustomerFailed', error.response ? error.response.data : error);
      throw error;
    }
  }

  async createCustomer(customer) {
    try {
      const response = await api.post('/api/customers', customer);
      const event = new CustomEvent('customerCreated', { detail: response.data });
      this.dispatchEvent(event);
      return response.data;
    } catch (error) {
      console.error('Fout bij het aanmaken van klant:', error);
      this.dispatchCustomEvent('createCustomerFailed', error.response ? error.response.data : error);
      throw error;
    }
  }

  async updateCustomer(id, customer) {
    try {
      const response = await api.put(`/api/customers/${id}`, customer);
      const event = new CustomEvent('customerUpdated', { detail: response.data });
      this.dispatchEvent(event);
      return response.data;
    } catch (error) {
      console.error('Fout bij het bijwerken van klant:', error);
      this.dispatchCustomEvent('updateCustomerFailed', error.response ? error.response.data : error);
      throw error;
    }
  }

  async deleteCustomer(id) {
    try {
      const response = await api.delete(`/api/customers/${id}`);
      const event = new CustomEvent('customerDeleted', { detail: id });
      this.dispatchEvent(event);
      return response.data;
    } catch (error) {
      console.error('Fout bij het verwijderen van klant:', error);
      this.dispatchCustomEvent('deleteCustomerFailed', error.response ? error.response.data : error);
      throw error;
    }
  }
}

export default CustomerService;
