import api from './axiosConfig';

class LogService {

  async getLogFiles() {
    const response = await api.get('/api/logs/files');
    return response.data;
  }

  async getLogs(filename) {
    const response = await api.get(`/api/logs/${filename}`);
    return response.data;
  }
}

export default LogService;