<template>
  <div class="container border rounded-3 p-3">
    <label for="orderStatussen" class="form-label mb-1">Filter</label>
    <div v-for="status in sortedOrderstatussen" :key="status.id" class="form-check form-switch">
      <input class="form-check-input" @change="filterStatusChanged()" type="checkbox" v-model="filter.status[`status_${status.id}`]" :id="`status_${status.id}`" key="" >
      <label class="form-check-label label-text" for="flexCheckDefault" :title="status.label">
        &nbsp;<i class="bi bi-circle-fill close" :class="getColor(status.id)"></i>&nbsp;{{ status.label }}
      </label>
    </div>
    <div class="dropdown mt-4 ">
      <button class="btn btn-primary dropdown-toggle" type="button" @click="toggleDropdown()" aria-expanded="false">Selecteer klanten</button>
      <div v-if="dropdownOpen" class=" px-2">
        <form class="px-4 py-3">
          <div v-for="customer in customers" :key="customer.id" class="form-check mb-2">
            <input type="checkbox" 
                   @change="filterStatusChanged()" 
                   class="form-check-input" 
                   v-model="filter.customer[customer.username.toLowerCase()]" 
                   :id="'dropdown_' + customer.id">
            <label class="form-check-label ms-2" :for="'dropdown_' + customer.id">
              {{ customer.name }}
            </label>
          </div>
        </form>
      </div>
    </div>
    <div>
      <p class="fs-6 text-start text-secondary"><i>{{ selectedCustomer }}</i></p>
    </div>
    <button type="button" 
            class="btn btn-secondary btn-sm mt-2" 
            @click="clearSelection">
      Wis selectie
    </button>
    <input type="text" class="rounded-input mt-3" v-model="filter.userAgentFilter" @input="filterStatusChanged()" placeholder="Filter op User Agent">
  </div>
</template>

<script>
import Utils from "../utils/Utils"
import { watch } from 'vue'
import ServiceFactory from '@/services/ServiceFactory'
import localDB from '../utils/localDB';
var capitalize = require('lodash/capitalize')
export default {
  name: 'FilterOrder',
  props: {
    orderstatussen: Array 
  },
  emits: ['filterStatusChanged'],
  data() {
    return {
      orderService: null,
      customerService: null,
      customers: [],
      filter: {
        status: {
          verwerkt:false, failed:false, open:false, dubbel:false, onjuist:false, handmatig:false, zonderRegels:false, unit4: false
        },
        customer: {},
        userAgentFilter: ''
      },
      selectedCustomer: "Geen selectie",
      userAgentFilter: '',
      dropdownOpen: false,
      sortedOrderstatussen: [],
    }
  },
  watch: {
    orderstatussen(newOrderstatussen) {
      if (newOrderstatussen) {
        this.sortedOrderstatussen = [...newOrderstatussen].sort((a, b) => a.sort_position - b.sort_position);
      } else {
        this.sortedOrderstatussen = [];
      }
    }
  },
  methods: {
    getColor(status) {
      return Utils.getColorForOrderStatus(status)
    },
    filterStatusChanged() {
      this.updateSelectedCustomerString()
      this.$emit('filterStatusChanged', this.filter)
    },
    updateSelectedCustomerString(){
      this.selectedCustomer = ""
      for(let customer in this.filter.customer) {
        let customerSelected = this.filter.customer[customer]
        if(customerSelected){
          this.selectedCustomer += `${capitalize(customer)}, `
        }
      }
      if(this.selectedCustomer.length > 0) {
        this.selectedCustomer = this.selectedCustomer.slice(0, -2)
      }else{
        this.selectedCustomer = "Geen selectie"
      }
      
    },
    toggleDropdown(){
      this.dropdownOpen = !this.dropdownOpen
    },
    async loadCustomers() {
      try {
        let response = await this.customerService.getCustomers();
        this.customers = response.data;
        console.log('customers: ', this.customers)
        // Dynamisch vullen van filter.customer object
        this.customers.forEach(customer => {
          this.filter.customer[customer.username.toLowerCase()] = false;
          
        });
      } catch (error) {
        console.error('Fout bij laden klanten:', error);
      }
    },

    async clearSelection() {
      // Reset status filters
      Object.keys(this.filter.status).forEach(key => {
        this.filter.status[key] = false;
      });
      
      // Reset customer filters
      Object.keys(this.filter.customer).forEach(key => {
        this.filter.customer[key] = false;
      });
      
      // Reset userAgent filter
      this.filter.userAgentFilter = '';
      
      // Clear localStorage
      await localDB.removeItem('orderFilter');
      
      // Update UI
      this.updateSelectedCustomerString();
      this.filterStatusChanged();
    }
  },
  async mounted() {
    this.orderService = ServiceFactory.getService('OrderService')
    this.orderService.addEventListener('ordersLoaded', () => {
      this.filterStatusChanged()
    });
    
    this.customerService = ServiceFactory.getService('CustomerService');
    await this.loadCustomers();
    
    const storedFilter = await localDB.getItem('orderFilter');
    if (storedFilter) {
      const parsedFilter = JSON.parse(storedFilter);
      console.log('parsedFilter: ', parsedFilter);
      // Alleen opgeslagen customer filters toepassen die nog bestaan
      Object.keys(parsedFilter.customer).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(this.filter.customer, key)) {
          this.filter.customer[key] = parsedFilter.customer[key];
        }
      });
      this.filter.status = parsedFilter.status;
      this.filter.userAgentFilter = parsedFilter.userAgentFilter;
      this.updateSelectedCustomerString();
    }
    
    watch(() => this.filter, async (newValue) => {
      await localDB.setItem('orderFilter', JSON.stringify(newValue));
    }, { deep: true });
  }
  
}
</script>

<style scoped>
.form-check-input {
  position: relative;
  top: -2px;
}
.form-check {
  display: flex;
  align-items: center;
}
.label-text {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;  
  display: inline-block;
}
.rounded-input {
  border-radius: 8px; 
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}
</style>