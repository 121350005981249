<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Facturenpagina</h4>
      <div class="row p-0 m-0">
        <div class="col-lg-3 col-md-4 mt-2 ">
          <FilterInvoice @filterStatusChanged="filterStatusChanged" :invoiceStatussen="invoiceStatussen" />
        </div>
        <div class="col-lg-9 col-md-8 ">
          <div class="table-responsive">
            <table class="table caption-top table-striped border table-hover">
              <caption>Lijst met facturen</caption>
              <thead>
                <tr>
                  <th scope="col" @click="sort('status')" :class="sortClass('status')">&nbsp;</th>
                  <th scope="col" @click="sort('updated_at')" :class="sortClass('updated_at')" class="text-center">Verwerkt</th>
                  <th scope="col" @click="sort('customer')" :class="sortClass('customer')">Klant</th>
                  <th scope="col" @click="sort('amount')" :class="sortClass('amount')" class="text-end">Bedrag</th>
                  <th scope="col" @click="sort('date')" :class="sortClass('date')" class="text-center">Fact.datum</th>
                  <th scope="col" @click="sort('number')" :class="sortClass('number')">Verkoopord.</th> 
                  <th scope="col" @click="sort('ref')" :class="sortClass('date')">Reference</th>
                  <th scope="col" @click="sort('number')" :class="sortClass('number')">Fact.nummer</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(invoice, index) in filteredInvoices" :key="index" class="clickable-row"
                  @click="goToInvoiceDetailPage(index)">
                  <td><i class="bi bi-circle-fill close" :class="getColor(invoice.status)"></i></td>
                  <td class="text-center">{{ getDateTime(invoice.updated_at) }}</td>
                  <td>{{ invoice.customer?.name }}</td>
                  <td class="text-end">{{ getAmount(invoice.amount) }}</td>
                  <td class="text-center">{{ getDate(invoice.date) }}</td>
                  <td>{{ invoice.verkooporder }}</td>
                  <td>{{ invoice.reference }}</td>
                  <td>{{ invoice.number }}</td>
                </tr>
              </tbody>
            </table>
            <div ref="sentinel" ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceFactory from "../services/ServiceFactory"
import FilterInvoice from "../components/FilterInvoice.vue"
import Utils from "../utils/Utils"

export default {
  name: 'InvoicesPage',
  props: ['user'],
  components: { FilterInvoice },
  data() {
    return {
      invoiceService: null,
      invoices: [],
      invoiceStatussen: [],
      filteredInvoices: [],
      currentSort: 'updated_at',
      currentSortDir: 'desc',
      webSocketService: null,
      page: 1,
      prevPage: 0,
      pageSize: 25,
      observer: null,
      currentFilter: null
    }
  },
  watch: {
    user: {
      handler: function (val) {
        if (val) {
          this.loadInvoices()
          this.loadInvoiceStatussen()
        }
      },
      deep: true
    }
  },
  methods: {
    getColor(status) {
      return Utils.getColorForInvoiceStatus(status)
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSort = s;
      }

      this.filteredInvoices = this.filteredInvoices.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        let aValue = a[this.currentSort];
        let bValue = b[this.currentSort];

        if (typeof aValue === 'object' && aValue !== null && 'label' in aValue) {
          aValue = aValue.label;
        }
        if (typeof bValue === 'object' && bValue !== null && 'label' in bValue) {
          bValue = bValue.label;
        }

        if (aValue === null) return 1;
        if (bValue === null) return -1;

        if (aValue < bValue) return -1 * modifier;
        if (aValue > bValue) return 1 * modifier;
        return 0;
      });
    },
    sortClass(column) {
      return {
        'sort-asc': this.currentSort === column && this.currentSortDir === 'asc',
        'sort-desc': this.currentSort === column && this.currentSortDir === 'desc'
      };
    },
    filterStatusChanged(filterObj) {
      this.currentFilter = filterObj;
      if(!this.invoices) return [];
      this.filteredInvoices = this.invoices.filter((invoice) => {
        if (!invoice.status || !invoice.customer) {
          return true;
        }
        const statusMatch = filterObj.status[`status_${invoice.status.id}`] || this.allFiltersUnchecked(filterObj.status);
        const customerMatch = filterObj.customer[invoice.customer?.name?.toLowerCase()] || this.allFiltersUnchecked(filterObj.customer);
        const userAgentMatch = !filterObj.userAgentFilter || (invoice.user_agent && invoice.user_agent.toLowerCase().includes(filterObj.userAgentFilter.toLowerCase()));
        return statusMatch && customerMatch && userAgentMatch;
      });
    },
    allFiltersUnchecked(type) {
      for (let i in type) {
        if (type[i]) {
          return false;
        }
      }
      return true;
    },
    getDateTime(isoString) {
      return Utils.formatDateTimeString(isoString)
    },
    getDate(isoString) {
      return Utils.formatDateString(isoString)
    },
    getAmount(amount) {
      return Utils.formatCurrency(amount)
    },
    goToInvoiceDetailPage(index) {
      this.$router.push({ name: 'InvoiceDetail', params: { id: this.filteredInvoices[index].id } });
    },
    async loadInvoiceStatussen() {
      this.invoiceStatussen = await this.invoiceService.getInvoiceStatussen();
    },
    async loadInvoices() {
      //prevent double loading of the same page
      if(this.prevPage === this.page) return;
      this.prevPage = this.page;
      const newInvoices = await this.invoiceService.getInvoices(this.page, this.pageSize);
      console.log('newInvoices', newInvoices)
      if(!newInvoices) return;
      newInvoices.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      this.invoices = [...this.invoices, ...newInvoices];
      this.filteredInvoices = this.invoices;
      this.page += 1;
      if (this.currentFilter) {
        this.filterStatusChanged(this.currentFilter);
        this.sort(this.currentSort);
      }
    },
    observeSentinel() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      }

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadInvoices(); 
          }
        });
      }, options);

      this.observer.observe(this.$refs.sentinel);
    },
  },
  async mounted() {
    this.invoiceService = ServiceFactory.getService('InvoiceService')
    this.invoiceService.addEventListener('getInvoicesFailed', () => {
      this.$emit('showToast', ['Fout bij ophalen facturen', 'bg-danger']) 
    })
    if(this.user) {
      await this.loadInvoices()
      await this.loadInvoiceStatussen()
      this.webSocketService = ServiceFactory.getService('WebSocketService')

      this.webSocketService.addEventListener('invoicesUpdated', (event) => {
        this.invoices = event.detail;
        this.invoices.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        this.filteredInvoices = this.invoices
        this.$nextTick(() => this.filterStatusChanged(this.currentFilter));
      });
      this.webSocketService.connect()
    }
    this.observeSentinel(); 
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
</script>

<style scoped>
th {
  cursor: pointer;
}

.sort-asc::after {
  content: ' ▲';
}

.sort-desc::after {
  content: ' ▼';
}
</style>