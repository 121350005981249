<template>
  <div class="customers-page">
    <div class="row">
      <div class="col-md-3">
        <h4>Klanten</h4>
        <ul class="list-group">
          <li v-for="customer in customers" :key="customer.id" 
              class="list-group-item customer-item" 
              :class="{ 'active custom-active': selectedCustomer && selectedCustomer.id === customer.id }"
              @click="selectCustomer(customer)">
            {{ customer.name }}
          </li>
        </ul>
        <button class="btn btn-primary mt-3 mb-3 ms-auto" @click="createNewCustomer">Nieuwe klant</button>
      </div>
      <div class="col-md-9">
        <h4>{{ selectedCustomer ? 'Bewerken' : 'Nieuwe klant' }}</h4>
        <form @submit.prevent="saveCustomer" class="mb-4">
          <div class="mb-3">
            <label for="name" class="form-label">Naam</label>
            <input type="text" class="form-control" id="name" v-model="editingCustomer.name" required>
          </div>
          <div class="mb-3">
            <label for="username" class="form-label">Gebruikersnaam</label>
            <input type="text" class="form-control" id="username" v-model="editingCustomer.username" required>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Wachtwoord</label>
            <PasswordInput 
              :value="editingCustomer.password" 
              @passwordChanged="updatePassword"
            />
            <small class="form-text text-muted" v-if="!isCreating">Laat leeg om het wachtwoord niet te wijzigen</small>
          </div>
          <!-- E-mails -->
          <div class="email-categories mb-4">
            <h4>E-mails</h4>
            <div v-for="category in emailCategories" :key="category" class="email-category mb-2">
              <label>{{ category.charAt(0).toUpperCase() + category.slice(1) }}</label>
              <div class="badge-wrapper d-flex align-items-center">
                <div class="badge-container me-2">
                  <span v-if="editingCustomer?.email?.[category]?.length === 0" class="badge-placeholder">
                    Geen e-mails
                  </span>
                  <span v-else v-for="(email, index) in editingCustomer?.email?.[category]" :key="index" 
                        class="badge me-2 d-inline-flex align-items-center bg-white text-dark" 
                        :class="badgeClass(category)"
                        style="border: 2px solid currentColor;"
                        @click="openEmailDialog(category, index)"
                        :title="email.email">
                    {{ email.name || email.email }}
                    <i class="bi bi-x-lg ms-2" 
                       @click.stop="deleteEmailFromBadge(category, index)"
                       style="cursor: pointer;"></i>
                  </span>
                </div>
                <button type="button" @click="openEmailDialog(category, -1)" class="btn btn-sm btn-primary">
                  <i class="bi bi-plus-lg d-md-none"></i>
                  <span class="d-none d-md-inline">Toevoegen</span>
                </button>
              </div>
            </div>
          </div>
          <!-- Debiteurnummer -->
          <h4>Debiteurnummer</h4>
          <div class="row mb-2">
            <div class="col-12">
              <div class="row mb-2">
                <div class="col">
                  <strong>Address ID</strong>
                </div>
                <div class="col">
                  <strong>Organisation Code</strong>
                </div>
                <div class="col">
                  <strong>Debtor Code</strong>
                </div>
                <div class="col-auto remove-button-col">
                  <strong>&nbsp;</strong> <!-- Placeholder voor de kolomkop -->
                </div>
              </div>
              <div v-for="(debtorCode, index) in editingCustomer.debtor_codes" :key="index" class="mb-3">
                <div class="row">
                  <div class="col">
                    <input type="text" class="form-control" v-model="debtorCode.addressId" placeholder="Address ID">
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" v-model="debtorCode.organisationCode" placeholder="Organisation Code">
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" v-model="debtorCode.debtorCode" placeholder="Debtor Code">
                  </div>
                  <div class="col remove-button-col d-flex justify-content-end">
                    <button type="button" class="btn btn-primary" @click="removeDebtorCode(index)">
                      <i class="bi bi-trash d-md-none"></i>
                      <span class="d-none d-md-inline">Verwijder</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 ">
              <div class="row">
                <div class="col m-0">
                  <button type="button" class="btn btn-primary ms-0 mb-3" @click="addDebtorCode">Debiteurcode toevoegen</button>
                </div>
                <div class="col ">
                  <div class="mb-3">
                    <div v-if="!isEditingDescription" @click="startEditingDescription" class="form-control description-field" style="min-height: 100px; cursor: pointer;">
                      {{ editingCustomer.debtor_codes_descr || 'Het default debiteurnummer dat wordt gebruikt voor het insturen van orders. (Bij één debiteurnummer maakt het niet uit welke sleutel wordt gebruikt.)' }}
                    </div>
                    <textarea v-else class="form-control description-field" v-model="editingCustomer.debtor_codes_descr" @blur="stopEditingDescription" rows="4"></textarea>
                  </div>
                </div>
                <div class="col-auto remove-button-col ">
                  <strong>&nbsp;</strong>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button type="submit" class="btn btn-primary">Opslaan</button>
            <button type="button" class="btn btn-primary ms-2" @click="deleteCustomer" v-if="selectedCustomer">Verwijderen</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Bootstrap 5 Modal -->
    <div class="modal fade" id="emailModal" tabindex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="emailModalLabel">Email Bewerken</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cancelEditEmail"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="editName" class="form-label">Naam</label>
              <input type="text" class="form-control" id="editName" v-model="editingEmail.name">
            </div>
            <div class="mb-3">
              <label for="editEmail" class="form-label">E-mail</label>
              <input type="email" class="form-control" id="editEmail" v-model="editingEmail.email">
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="applyAll" v-model="applyToAllCategories">
              <label class="form-check-label" for="applyAll">
                Pas deze wijziging toe op alle e-mailcategorieën
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="cancelEditEmail">Annuleren</button>
            <button type="button" class="btn btn-primary" @click="saveEmail">Bewaar</button>
            <button type="button" class="btn btn-danger" v-if="editingEmailIndex >= 0" @click="deleteEmailFromModal">Verwijder</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceFactory from '@/services/ServiceFactory';
import PasswordInput from '@/components/PasswordInput.vue';
import Utils from '@/utils/Utils';
export default {
  name: 'CustomersPage',
  components: {
    PasswordInput
  },
  data() {
    return {
      customers: [],
      selectedCustomer: null,
      editingCustomer: {},
      isCreating: true,
      service: null,
      isEditingDescription: false,
      editingEmail: { name: '', email: '' },
      editingEmailCategory: '',
      editingEmailIndex: -1,
      showEmailDialog: false,
      emailCategories: ['success', 'notification', 'error'],
      applyToAllCategories: false,
    };
  },
  async created() {
    this.service = ServiceFactory.getService('CustomerService')
    this.service.addEventListener('getCustomersFailed', (error) => {
      this.$emit('showToast', [error.detail.error, 'bg-danger']) 
    })
    this.service.addEventListener('createCustomerFailed', (error) => {
      this.$emit('showToast', [error.detail.error, 'bg-danger']) 
    })
    this.service.addEventListener('updateCustomerFailed', async (error) => {
      console.log('updateCustomerFailed', error.detail.error)
      this.$emit('showToast', [error.detail.error, 'bg-danger']) 
      await this.loadCustomers()
      this.selectCustomer(this.selectedCustomer)
    })
    this.service.addEventListener('deleteCustomerFailed', async (error) => {
      this.$emit('showToast', [error.detail.error, 'bg-danger']) 
      await this.loadCustomers()
    })
    await this.loadCustomers();
    this.createNewCustomer(); // Initialize with a new customer form
    this.editingCustomer.email = {
      success: [],
      notification: [],
      error: []
    };
  },
  async mounted() {
    await this.loadCustomers();
    if (this.customers.length > 0) {
      setTimeout(() => {
        this.selectCustomer(this.customers[0]);
      }, 200);
    }
  },
  methods: {
    async loadCustomers() {
      const response = await this.service.getCustomers();
      this.customers = response.data;
    },
    selectCustomer(customer) {
      this.selectedCustomer = customer;
      this.editingCustomer = { ...customer, password: '' };
      this.isCreating = false;
    },
    createNewCustomer() {
      this.selectedCustomer = null;
      this.editingCustomer = { 
        name: '',
        username: '',
        password: '',
        email: '',
        customer_code: '',
        organization_code: '',
        debtor_codes: [],
        debtor_codes_descr: '',
      };
      this.isCreating = true;
    },
    addDebtorCode() {
      this.editingCustomer.debtor_codes.push({ key: '', code: '' });
    },
    removeDebtorCode(index) {
      if (confirm('Weet je zeker dat je deze debiteurcode wilt verwijderen?')) {
        this.editingCustomer.debtor_codes.splice(index, 1);
      }
    },
    async saveCustomer() {
      const customerData = { ...this.editingCustomer };
      if (!this.isCreating && !customerData.password) {
        delete customerData.password;
      }
      try {
        let savedCustomer;
        let response;
        if (this.isCreating) {
           response = await this.service.createCustomer(customerData);
          savedCustomer = response.data
          this.$emit('showToast', ['Klant succesvol aangemaakt', 'bg-success']);
        } else {
          response = await this.service.updateCustomer(customerData.id, customerData);
          savedCustomer = response.data
          this.$emit('showToast', ['Klant succesvol bijgewerkt', 'bg-success']);
        }
        console.log('savedCustomer', savedCustomer)
        await this.loadCustomers();
        this.selectCustomer(this.customers.find(c => c.id === savedCustomer.id));
      } catch (error) {
        this.$emit('showToast', [error.message, 'bg-danger']);
      }
    },
    async deleteCustomer() {
      if (confirm(`Weet je zeker dat je ${this.selectedCustomer.name} wilt verwijderen?`)) {
        try {
          await this.service.deleteCustomer(this.selectedCustomer.id);
          this.$emit('showToast', ['Klant succesvol verwijderd', 'bg-success']);
          await this.loadCustomers();
          this.createNewCustomer();
        } catch (error) {
          this.$emit('showToast', [error.message, 'bg-danger']);
        }
      }
    },
    updatePassword(newPassword) {
      this.editingCustomer.password = newPassword;
    },
    startEditingDescription() {
      this.isEditingDescription = true;
    },
    stopEditingDescription() {
      this.isEditingDescription = false;
    },
    badgeClass(category) {
      switch (category) {
        case 'success':
          return 'border-success';
        case 'notification':
          return 'border-warning';
        case 'error':
          return 'border-danger';
        default:
          return '';
      }
    },
    editEmail(category, index) {
      this.editingEmailCategory = category;
      this.editingEmailIndex = index;
      this.editingEmail = { ...this.editingCustomer.email[category][index] };
    },
    openEmailDialog(category, index) {
      this.editingEmailCategory = category;
      this.editingEmailIndex = index;
      if (index >= 0) {
        this.editingEmail = { ...this.editingCustomer.email[category][index] };
      } else {
        this.editingEmail = { name: '', email: '' }; // Lege velden voor nieuwe e-mail
      }
      const modalElement = document.getElementById('emailModal');
      if (modalElement) {
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
      } else {
        console.error('Modal element not found');
      }
    },
    async saveEmail() {
      if (!this.editingEmail.email || !Utils.isValidEmail(this.editingEmail.email)) {
        this.$emit('showToast', ['Vul een geldig e-mailadres in', 'bg-danger']);
        return;
      }

      try {
        if (this.applyToAllCategories) {
          if (this.editingEmailIndex >= 0) {
            // Update bestaande email in alle categorieën
            this.emailCategories.forEach(category => {
              const emailIndex = this.editingCustomer.email[category].findIndex(
                email => email.email === this.editingEmail.email
              );
              if (emailIndex >= 0) {
                this.editingCustomer.email[category][emailIndex] = { ...this.editingEmail };
              }
            });
          } else {
            // Nieuwe email toevoegen aan categorieën waar hij nog niet bestaat
            this.emailCategories.forEach(category => {
              const exists = this.editingCustomer.email[category].some(
                email => email.email === this.editingEmail.email
              );
              if (!exists) {
                this.editingCustomer.email[category].push({ ...this.editingEmail });
              }
            });
          }
        } else {
          if (this.editingEmailIndex >= 0) {
            this.editingCustomer.email[this.editingEmailCategory][this.editingEmailIndex] = { ...this.editingEmail };
          } else {
            const exists = this.editingCustomer.email[this.editingEmailCategory].some(
              email => email.email === this.editingEmail.email
            );
            if (!exists) {
              this.editingCustomer.email[this.editingEmailCategory].push({ ...this.editingEmail });
            }
          }
        }

        await this.service.updateCustomer(this.editingCustomer.id, this.editingCustomer);
        this.$emit('showToast', [
          this.editingEmailIndex >= 0 ? 'E-mail succesvol bijgewerkt' : 'E-mail succesvol toegevoegd', 
          'bg-success'
        ]);
        const modal = window.bootstrap.Modal.getInstance(document.getElementById('emailModal'));
        modal.hide();
        this.applyToAllCategories = false;
      } catch (error) {
        this.$emit('showToast', ['Er is een fout opgetreden bij het opslaan van de e-mail', 'bg-danger']);
      }
    },
    async deleteEmailFromBadge(category, index) {
      try {
        // Verwijder specifieke email uit de categorie met splice
        this.editingCustomer.email[category].splice(index, 1);
        await this.service.updateCustomer(this.editingCustomer.id, this.editingCustomer);
        this.$emit('showToast', ['E-mail succesvol verwijderd', 'bg-success']);
      } catch (error) {
        this.$emit('showToast', ['Er is een fout opgetreden bij het verwijderen van de e-mail', 'bg-danger']);
      }
    },
    async deleteEmailFromModal() {
      try {
        if (this.applyToAllCategories) {
          // Verwijder email uit alle categorieën
          this.emailCategories.forEach(category => {
            this.editingCustomer.email[category] = this.editingCustomer.email[category].filter(
              e => e.email !== this.editingEmail.email
            );
          });
        } else {
          // Verwijder email alleen uit de huidige categorie
          this.editingCustomer.email[this.editingEmailCategory] = this.editingCustomer.email[this.editingEmailCategory].filter(
            e => e.email !== this.editingEmail.email
          );
        }
        
        await this.service.updateCustomer(this.editingCustomer.id, this.editingCustomer);
        this.$emit('showToast', ['E-mail succesvol verwijderd', 'bg-success']);
        const modal = window.bootstrap.Modal.getInstance(document.getElementById('emailModal'));
        modal.hide();
        this.applyToAllCategories = false;
      } catch (error) {
        this.$emit('showToast', ['Er is een fout opgetreden bij het verwijderen van de e-mail', 'bg-danger']);
      }
    },
    cancelEditEmail() {
      const modal = window.bootstrap.Modal.getInstance(document.getElementById('emailModal'));
      modal.hide();
      this.applyToAllCategories = false;  // Reset de checkbox
    }
  }
};
</script>

<style scoped>
.custom-active {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.list-group-item.custom-active:hover {
  background-color: var(--primary) !important;
}

.customer-item {
  cursor: pointer;
}

.customer-item:hover {
  background-color: var(--light);
}

.description-field {
  font-size: 0.7em;
  background-color:#ffffff;
  border-color: #999;
}

.description-field:focus {
  background-color: #fff;
  border-color: #999;
}

.remove-button-col {
  width: 100px; 
}

.badge-wrapper {
  display: flex;
  align-items: center;
}

.badge-container {
  border: 1px solid #ccc;
  padding: 5px;
  min-height: 40px;
  border-radius: 4px;
  flex-grow: 1;
}

.badge-placeholder {
  color: #999; 
  font-style: normal;
}

.badge {
  margin-right: 0.5rem;
  cursor: pointer;
}

.btn {
  margin-left: 10px;
}
</style>
